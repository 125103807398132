import i18n from '@/lang';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { Message, MessageBox } from 'element-ui';
import { UserStoreModule } from '@/store/UserModule';
interface ResponseData {
  /** code
   * "0001","缺少参数"
   * "0002","无效参数"
   * "0003","无效签名"
   * "0004","验证失败"
   * "0005","服务不可用"
   * "0006","会话已失效" 会话已失效
   * "0007","没权限"
   * "1001","禁止访问"
   * "1002","请求错误"
   * "1003","请求成功"
   * "1004","服务器错误"
   * "1005","访问外部服务错误"
   * "1006","网关超时"
   * "1007","feign调用失败"
   */
  code: string;
  data: {};
  datas: [];
  success: boolean;
  message: string;
}

// 白名单
const whiteList=['/appFile/download','/appSystem/config/getConfigValueByConfigKey', '/authentication/oauth/token','/appFile/downloadByPublic'];

const service: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000,
});
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.headers = UserStoreModule.headers;
   
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

service.interceptors.response.use(
  (response: AxiosResponse<ResponseData>) => {
    const res:any = response.data;
    if (response.status === 401) {
      MessageBox.confirm(
        i18n.t('loginTimeout') as string,
        i18n.t('errorMsg') as string,
        {
        confirmButtonText: i18n.t('reLogin') as string,
        type: 'warning',
        showClose: false,
        closeOnClickModal: false,
        showCancelButton: false,
      }).then(() => {
        UserStoreModule.resetUserInfo()
      });
    }else if(response.status !== 200){
      Message.error((i18n.t('common.errorCallAdmin') as any));
    }else if(response.status === 200){
      if (res.code === '1003' || res.access_token) {
        return response;
      }else{
        const url: any = response.config.url;
        if (whiteList.indexOf(url) !== -1) {
          return response;
        } else {
          Message.error({
            message: res.message ? res.message : i18n.t('common.errorCallAdmin') as string,
            dangerouslyUseHTMLString: true,
          });
        }
      }
    }
    return Promise.reject(res);
  },
  (error: AxiosError) => {
    if (error.config.url === '/authentication/oauth/token') {
      Message.error((error.response as any).data.error_description);
      return Promise.reject(error);
    }
    Message.error((i18n.t('common.errorCallAdmin') as any));
    return Promise.reject(error);
  },
);

export const transformRequest = [(data: any) => {
  let ret = '';
  for (const it in data) {
    if (it) {
      ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
    }
  }
  return ret;
}];

export default service;
