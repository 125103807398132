import { CusRouteConfig } from './index'

const schoolRouter: CusRouteConfig = {
  path: 'annual-school-list',
  name: 'adminAnnualSchoolList',
  meta: {
    name: 'adminAnnualSchoolList',
    icon: '&#xe62e;',
    permission: ['institutionConventionManagement','institutionConventionView', 'institutionConventionAdd']
  },
  component: () => import("@/view/admin/school_module/annual_school_list.vue"),
  children: [
    {
      path: 'annual-school-detail/:id',
      name: 'adminAnnualSchoolDetail',
      meta: {
        name: 'adminAnnualSchoolDetail',
        icon: '',
        isNotAside: true,
        permission: ['institutionConventionView','institutionConventionEdit', 'institutionConventionDelete', 'institutionConventionAdd'],
      },
      component: () => import("@/view/admin/school_module/school_detail_module/annual_school_detail.vue"),
    }
  ]
};
export default schoolRouter;