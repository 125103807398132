import {Module, VuexModule, getModule, Mutation} from 'vuex-module-decorators';
import store from '@/store';
import router,{ 
  CusRouteConfig, 
  CusRoute, 
  getAllAdminRoutes,
  getCommonAdminRoutes,
  getAsyncAdminRoutes 
} from '@/router'
import { deepClone } from '@/utils';
import { SchoolSelect } from '@/interface';

@Module({
  name: 'LayoutModule',
  store,
  dynamic: true,
  namespaced: true
})
export default class LayoutModule extends VuexModule {
  asideMenu: CusRouteConfig[] = [];
  navList: CusRoute[] = [
    {
      path: 'index',
      name: 'adminIndex',
      fullPath: '/admin/index',
      hash: '',
      query: {},
      params: {},
      matched: [],
      meta: {
        name: 'index',
        icon: '&#xe602;',
        affix: true,
      },
    }
  ];
  breadcrumbList: CusRouteConfig[] = [];
  navItemActive = 0;
  asideMenuActive: number[] = [0];  // 当前选中的侧边菜单是
  asideMenuStatus: number[] = [1]; // 展开子项的菜单有这些
  asideMenuApperance: 'expand' | 'shrink' = 'expand';
  get gerResourceList(){
    // 去除公共的，和不显示在侧边栏 和 没有permission值的
    let resourceList: CusRouteConfig[] = [];
    function filterRoute(route: CusRouteConfig[], newRoute: CusRouteConfig[]){
      for (let i = 0, lng = route.length; lng > i; i++ ){
        const meta = route[i].meta;
        if (meta && !meta.isCommon && meta.permission){
          let j = newRoute.push(deepClone(route[i]));
          const children: undefined| CusRouteConfig[] = route[i].children;
          if (children && children.length){
            newRoute[j-1].children = [];
            filterRoute( children, newRoute[j-1].children as CusRouteConfig[])
          }
        }
      }
    }
    filterRoute(this.asideMenu, resourceList);
    return resourceList
  }
  get getAsideMenuList(){
    let menuAside: CusRouteConfig[] = [];
    function filterRoute(route: CusRouteConfig[], newRoute: CusRouteConfig[]){
      for (let i = 0, lng = route.length; lng > i; i++ ){
        const meta = route[i].meta;
        if (meta && !meta.isNotAside){
          let j = newRoute.push(deepClone(route[i]));
          const children: undefined| CusRouteConfig[] = route[i].children;
          if (children && children.length){
            newRoute[j-1].children = [];
            filterRoute( children, newRoute[j-1].children as CusRouteConfig[])
          }
        }
      }
    }
    filterRoute(this.asideMenu, menuAside);
    return menuAside;
  }
  @Mutation
  initAsideMenu(newAsideMenu: CusRouteConfig[]){
    this.asideMenu = newAsideMenu;
  }
  @Mutation
  pushNavList(item: CusRoute){
    const hadItem = this.navList.find(nav => nav.fullPath == item.fullPath);
    const index = !hadItem? this.navList.push(item) - 1: this.navList.indexOf(hadItem);
    this.navItemActive = index;
  }
  @Mutation
  setNavItemActive(index: number){
    this.navItemActive = index;
  }
  @Mutation
  removeNavItem(index: number){
    if (this.navItemActive == index){
      const {name, query, params} = this.navList[index-1];
      router.push({name: name || 'adminIndex', query, params});
    }
    this.navList.splice(index, 1);
  }
  // 删除详情 跳转的页面由当前页自己决定 方便逻辑跳转
  @Mutation
  removeDeletePage(index: number){
    this.navList.splice(index, 1);
  }
  @Mutation
  initNavItem(){
    this.navList.splice(1);
  }
  @Mutation
  setAsideMenuActive(index: number[]){
    this.asideMenuActive = index;
  }
  @Mutation
  shouldExpandMenuItem(index: number){
    const i = this.asideMenuStatus.indexOf(index);
    i >= 0 ? this.asideMenuStatus.splice(i,1) : this.asideMenuStatus.push(index);
  }
  @Mutation
  shouldExpandAsideMenu(){
    this.asideMenuApperance = this.asideMenuApperance == 'expand' ? 'shrink' : 'expand'
  }
  @Mutation
  setMenuAndBreadFromRoute(route: CusRoute){
    if(this.asideMenu.length == 0){
      return [];
    }
    const currIndex: number[] = [];
    const currBreadList: CusRouteConfig[] = [];
    function menuRoute(menus: CusRouteConfig[], index: number[] = [], lineMenu: CusRouteConfig[] = []){
      let j = -1;
      for (let i = 0, lng = menus.length; lng > i ; i++) {
        let isNotAside = menus[i].meta.isNotAside;
        !isNotAside && j++;
        if(menus[i].name == route.name){
          const paramsKeys = Object.keys(route.params);
          if (paramsKeys.length) {
            if (route.params){
              if( JSON.stringify(route.params) === JSON.stringify(menus[i].meta.params)) {
                currIndex.push(...index, j);
                currBreadList.push(...lineMenu, menus[i]);
                return;
              }
              if (isNotAside){
                currIndex.push(...index, j);
                currBreadList.push(...lineMenu, menus[i]);
                return;
              }
            } else {
              currIndex.push(...index, j);
              currBreadList.push(...lineMenu, menus[i]);
              return;
            } 
            continue;
          } 
          currIndex.push(...index, j);
          currBreadList.push(...lineMenu, menus[i])
        } else {
          const children = menus[i].children;
          children && menuRoute(children, index.concat(!isNotAside? j :i), lineMenu.concat(menus[i]));
        }
      }
    }
    menuRoute(this.asideMenu);
    if(currIndex.length) {
      if (this.asideMenuStatus.indexOf(currIndex[0]) < 0){
        this.asideMenuStatus.push(currIndex[0]);
      }
      if (currIndex[0] != -1 ){
        while(currIndex[currIndex.length-1] == -1){
          currIndex.pop();
        }
        if (this.asideMenuActive.join('') != currIndex.join('')) {
          
          this.asideMenuActive = currIndex;
        }
      } else {
        this.asideMenuActive = []
      }
    }
    if(currBreadList.length){
      this.breadcrumbList = currBreadList;
    }
    route = deepClone(route,[],['matched']);
    const lastCurr = currBreadList[currBreadList.length-1];
    if(lastCurr && lastCurr.meta && lastCurr.meta.cus_name) {
      route.meta.cus_name = lastCurr.meta.cus_name;
    } else if (route.query.cus_name){
      route.meta.cus_name = route.query.cus_name;
    } else if (route.params.id){
      route.meta.cus_name = route.params.id;
    }
    
    const hadItem = this.navList.find(nav => nav.fullPath == route.fullPath);
    const navIndex = !hadItem? this.navList.push(route) - 1: this.navList.indexOf(hadItem);
    this.navItemActive = navIndex;
  }
  /**
   * 设置侧边栏
   * @param {obj['isSupperPermission']} 是否超管
   * @param {obj['permission']} 权限列表
   * @param {obj['schoolList']} 学校列表
   */
  @Mutation
  setMenuAside({ isSupperPermission, permission, schoolList }: {isSupperPermission: boolean, permission: string[], schoolList: SchoolSelect[]}){
    function dfsRoutes(routes: CusRouteConfig[], myPermission: {[key:string]:any}, newRoutes: CusRouteConfig[] = []){
      for(let i = 0, lng = routes.length; lng > i; i++){
        const meta = routes[i].meta;
        if(meta && meta.permission ) {
          let filterPer = meta.permission.filter((item: string) =>{
            return myPermission[item]
          })
          
          if(meta.permission.length == 0 || filterPer.length > 0) {
            let j = newRoutes.push(JSON.parse(JSON.stringify(routes[i]))) - 1;
            newRoutes[j].children = [];
            newRoutes[j].meta.permission = filterPer;
            const children: CusRouteConfig[] | undefined = routes[i].children;
            children && children.length > 0 && dfsRoutes(children, myPermission, newRoutes[j].children)
          }
        }
      }
    }
    let allAdminPermission: CusRouteConfig[] = [];
    // 超管
    if(isSupperPermission){
      allAdminPermission = getAllAdminRoutes(false);
    } else {
      // 过滤权限
      allAdminPermission = getCommonAdminRoutes();
      if(permission.length > 0){
        let asyncAdminRoutes = getAsyncAdminRoutes();
        const tempPermission: {[key:string]:any} = {};
        for (let i = 0, lng = permission.length; lng > i; i++){
          tempPermission[permission[i]] = true;
        }
        const addPermission: CusRouteConfig[] = [];
        dfsRoutes(asyncAdminRoutes, tempPermission, addPermission);
        allAdminPermission.push(...addPermission);
        const schoolListRoute = allAdminPermission.find(item=>item.name == 'adminSchoolList')
        if(schoolListRoute) {

          const newSchoolList = schoolList? schoolList.map(item => {
            return {
              name: "adminSchoolDetail",
              path: "schoolDetail/:id",
              meta: {
                query: {cus_name: item.name},
                permission: '0',
                cus_name: item.name,
                params: {id: item.guid }
              }
            }
          }) : [];
          schoolListRoute.children?.unshift(...newSchoolList);
        }
      } 
    }
    this.asideMenu = allAdminPermission;
  }
  /**
   * 普通用户新增学校到侧边栏
   * @param school 
   */
  @Mutation
  addAsideMenubySchool(school: SchoolSelect){
    const schoolListRoute = this.asideMenu.find(item=>item.name == 'adminSchoolList');
    if (schoolListRoute) {
      schoolListRoute.children?.push({
        name: "adminSchoolDetail",
        path: "schoolDetail/:id",
        meta: {
          query: {cus_name: school.name},
          permission: '0',
          cus_name: school.name,
          params: {id: school.guid }
        }
      })
    }
  }
  /**
   * 普通用户删除侧边栏的学校
   * @param guid 学校的guid
   */
  @Mutation
  delAsideMenubySchool(guid: string){
    const schoolListRoute = this.asideMenu.find(item=>item.name == 'adminSchoolList');
    if (schoolListRoute){
      const index = schoolListRoute.children?.findIndex(item=>item.meta.params&&item.meta.params.id == guid);
      if(index){
        schoolListRoute.children?.splice(index, 1);
      }
    }
  }
}

export const LayoutStoreModule = getModule(LayoutModule);