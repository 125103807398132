import Vue from 'vue';
import Vuex from 'vuex';
 
Vue.use(Vuex);
export default new Vuex.Store({
state: {
  env: 'retrofitr_serve'
},
getters: {
  getPublicFileUrl(state) {
    switch(state.env) {
      case 'dev_serve':
        return 'http://192.168.1.16:9080'
      case 'test_serve':
        return 'http://192.168.1.17:9080'
      case 'prd_serve':
        return 'https://ibs.geteducation.net'
      case 'retrofitr_serve':
        return 'http://192.168.1.16:9080'
    }
  }
},
});