import { CusRouteConfig } from './index'

const schoolRouter: CusRouteConfig = {
  path: 'schoolList',
  name: 'adminSchoolList',
  meta: {
    name: 'schoolList',
    icon: '&#xe62e;',
    permission: ['institutionManagement', 'institutionView', 'institutionAdd']
  },
  component: () => import("@/view/admin/school_module/school_list.vue"),
  children: [
    {
      path: 'schoolDetail/:id',
      name: 'adminSchoolDetail',
      meta: {
        name: 'schoolDetail',
        icon: '',
        isNotAside: true,
        permission: ['institutionView', 'institutionEdit', 'institutionDelete', 'institutionAdd'],
      },
      component: () => import("@/view/admin/school_module/school_detail_module/school_detail.vue"),
    }
  ]
};
export default schoolRouter;