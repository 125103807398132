import element from './element';

const route = {
  index: '用戶主頁',
  schoolList: '學校',
  schoolDetail: '學校詳情',
  setting: '設置',
  systemManagement: '系统管理',
  userManagement: '用戶賬號管理',
  userGroup: '用戶組',
  userAdd: '新增用戶',
  userDetail: '用戶詳細',
  adminError: '錯誤'
}
const requestError = {
  error_0001: '缺少參數',
  error_0002: '無效參數',
  error_0003: '無效簽名',
  error_0004: '驗證失敗',
  error_0005: '服務不可用',
  error_0006: '會話已失效',
  error_0007: '沒權限',
  error_1001: '禁止訪問',
  error_1002: '請求錯誤',
  error_1003: '請求成功',
  error_1004: '服務器錯誤',
  error_1005: '訪問外部服務錯誤',
  error_1006: '網關超時',
  error_1007: 'feign調用失敗',
}
export default {
  ...element,
  ...route,
  ...requestError,
  systemName: 'Information Board',
  user: '用戶',
  pleaseInputAccount: '請輸入賬號',
  pleaseInputPwd: '請輸入密碼',
  pleaseInputAgain: '請確認密碼',
  login: '登錄',
  register: '註冊',
  welcomeLogin: '歡迎登錄',
  noHaveAccount: '還沒有賬號?',
  haveAccount: '已經有賬號?',
  registerInstantly: '立即註冊',
  loginInstantly: '立即登錄',
  register_login_tip: '請填寫8-12位密碼，需包含大小寫字母及合法特殊符號',
  two_password_not_equal: '兩次輸入的密碼不正確',
  position: '位置',
  changePassword: '修改密碼',
  searchKeyWord: '輸入關鍵字搜索',
  search: '搜索',
  create: '創建',
  preview: '預覽',
  opera: '操作',
  welcomeTo: '歡迎進入',
  edit: '編輯',
  delete: '刪除',
  updatingFiles:'上傳',
  uploadCover: '上傳學校封面',
  desc: '描述',
  uploadAttachment:'上傳附件',
  uploadInstructions:'附件上傳說明',
  instructionsOne: '類型：系統附件上傳支持的文件格式有：jpg，jpeg，gif，doc，docx，xls，xlsx，ppt，pptx，pdf, mov, mp4',
  instructionsTwo: '大小：上傳文件大小限制50M以下。',
  imageLimit: '支持GIF、JPG、PNG類型的文件，每個圖片大小限制為5k-2M',
  failUploadTip:'當前格式無法上傳，請留意上傳提示!',
  typeIsEmpty:'請選擇類型',
  remarkIsEmpty:'請填寫備註',
  fileNameIsEmpty:'請上傳附件',
  submit: '提交',
  underSubmission: '未提交',
  save: '保存',
  cancel: '取消',
  schoolInfo: '學校資料',
  add: '新增',
  forgetPassword: '忘記密碼',
  getCode: '獲取驗證碼',
  code: '驗證碼',
  confirmRegister: '確定註冊',
  phoneRegion: '區域號',
  pleaseSelectPhoneRegion: '請選擇區域號',
  errorPhoneNumberInRegion: '當前區域號的手機號碼格式不正確',
  pleaseInputCode: '請輸入驗證碼',
  accountValidateTip: '只允許數字，英文，中文，下劃線',
  confirmResetPasswod: '確定修改密碼',
  privatePersonalInfo: '個人信息',
  oldPassword: '舊密碼',
  newPassword: '新密碼',
  confirmNewPassword: '確定新密碼',
  pleaseInput: '請輸入',
  pleaseInputPersonalInfoCompletely: '（請填寫完整的個人信息）',
  userName: '姓名',
  userEnglishName: '英文名',
  gender: '性別',
  birthday: '出生日期',
  pleaseSelectBirthday: '選擇日期',
  insititution: '所屬公司/機構',
  componeyPosition: '所屬職位',
  mobilePhone: '移動電話',
  wechat: '微信',
  zipCode: '郵編',
  contactAddress: '聯系地址',
  addressDetail: '詳細地址',
  male: '男',
  female: '女',
  pleaseChooseCountryProvinceCity: '選擇國家/州省/城市',
  inputKeyword: '輸入信息搜索',
  cancelEdit: '取消編輯',
  cancelClose: '取消關閉',
  allCountry: '全部國家',
  totalPage: '共{total}條記錄，第{currentPage}/{pageTotal}頁',
  attachment: '附件',
  attachmentDesc: '附件描述',
  schoolAttachUpload: '學校附件上傳',
  editSchoolCover: '編輯學校封面',
  reset: '重置',
  refresh: '刷新',
  userId: '用戶編號',
  loginAccount: '登錄賬號',
  loginPassword: '登錄密碼',
  confirmPassword: '確定密碼',
  createPerson: '創建人',
  createTime: '創建時間',
  bascalInfo: '基本信息',
  hightSetting: '高級設置',
  pleaseSelect: '請選擇',
  systemAutoCreate: '系統自動生成',
  confirmUpdate: '確定更新',
  accountIsActive: '賬號是否激活',
  yes: '是',
  no: '否',
  phoneNum: '手機號',
  resourceNum: "資源數",
  personNum: "人員數",
  changePerson: "修改人",
  changeTime: "修改時間",
  moveUp: '上移',
  moveDown: '下移',
  resourceAllocation: '配置資源',
  allUser: '所有用戶',
  currentUser: '當前用戶',
  pleaseSelectUserGroup: '請選擇用戶組',
  error: '錯誤',
  country: '國家',
  nature: '學校性質',
  studentCount: '學生人數',
  schoolName: '學校名稱',
  website: '官網',
  pdfSize: '附件數',
  schoolNameChn: '學校中文名稱',
  establishedDate: '成立時間',
  checkTime: '請選擇日期時間',
  tip:'提示',
  sthSuccess: '{sth}成功',
  sthFail: '{sth}失敗',
  nameEmpty: '請填寫學校名稱',
  nameChnEmpty: '請填寫學校中文名稱',
  natureEmpty: '請填寫學校性質',
  studentCountEmpty: '請填寫學生人數',
  websiteEmpty: '請填寫官網',
  countrySelectEmpty: '請選擇國家',
  establishedDateEmpty: '請選擇成立時間',
  schoolWebsite: '學校官網',
  editSuccess: '編輯成功',
  addSuccess: '新增成功',
  editFail: '編輯失敗',
  addFail: '新增失敗',
  publicSchool: '公立',
  privateSchool: '私立',
  deleteConfirmMsg: '刪除後不能恢復，確認刪除該{name}？',
  data:'數據',
  confirm: '確定',
  delSucc: '刪除成功',
  delFail: '刪除失敗',
  delCancel: '刪除取消',
  operaConfirmTips: '操作確認',
  uploadType: '上傳類型',
  schoolPicture: '學校環境圖',
  schoolFile: '學校附件',
  afterSecond: '{second}s後',
  exit: '退出登錄',
  privatePersonalInfoUpdate: '個人信息修改',
  pleaseInputNickname: '請輸入昵稱',
  loginTimeout: '登錄超時',
  errorMsg: '錯誤信息',
  reLogin: '重新登錄',
  groupKey:'組別Key',
  groupName:'組別名稱',
  viewOrder:'排序',
  schoolInfoType: '學校資訊類型',
  schoolInfoTypeEmpty: '請選擇學校資訊類型',
  descEmpty: '請填寫描述類型',
  saveSuss: '保存成功',
  saveFail: '保存失敗',
  uploadSuccess: '上傳成功',
  checkUrl: '請輸入合法的網址',
  showImg:'圖片展示',
  close: '關閉',
  serverError: '服務器出錯',
  errorCallAdmin:'未知錯誤，請聯係管理員',
  downloadAttach: '下載附件',
  pleaseSelectState: '請選擇州省',
  pleaseSelectCity: '請選擇城市',
  userDetailUpdate: '用戶信息修改',
  check: '查看',
  pleseUploadSchoolCover: '請上傳學校封面',
  type: '類型',
  schoolIntroductionVideo: '學校介紹視頻',
  schoolProfile: '學校概要',
  schoolAdvatanges: '特色優勢',
  schoolAddress: '學校地理位置',
  schoolAdmission: '學費及入學要求',
  videoTip: '（點擊觀看學校視屏）',
  builderYear: '創校年份',
  pepole: '人',
  schoolType: '學校類型',
  schoolFileDownload: '相關附件下載',
  UploadBackgroundImage: '上傳背景圖片',
  backgroundImageLimit: '支持GIF、JPG、PNG類型的文件，每個圖片大小限制為5k-500K',
  backgroundImgTip: '建議上傳1920 × 1080px的高清背景圖片',
  attachmentFileTip: '類型：系統上傳支持的文件格式和大小限制',
  attachmentFileTipOne: '1、附件上傳：doc，docx，xl​​s，xlsx，ppt，pptx，pdf；每個文件大小限制50M以下。',
  attachmentFileTipTwo: '2、圖片上傳：png，gif，jpg；每個圖片大小限制為5k-500K。',
  attachmentFileTipThree: '3、視頻上傳：mov，mp4；每個視頻大小限制為限制50M以下。',
  detailsBackgroundImage: '詳情背景圖',
  LocationBackgroundMap: '地理位置背景圖',
  coverUploadInstructions: '学校封面上傳說明',
  welcome: '歡迎',
  coverImageEmpty: '暫無上傳圖片',
  schoolListEmpty: '關鍵字暫無匹配到學校',
  register_account_tip: '請填寫只包括字母大小寫和數字的3-20位賬號',
  pleaseInputPhoneNumOrAccount: '請輸入手機號/賬號',
  accountExited: '賬號已存在',
  mobileExited: '手機號碼已存在',
  mobileExitedTip: '尊敬的用戶您好，您輸入的手機號已經在我們旗下的【{platform}】平臺進行註冊使用，您可以通過手機號+密碼進行登錄。若忘記密碼，請點擊下面的忘記密碼進行修改，謝謝您的配合！',
  updateSuccess: '修改成功',
  city: '城市',
  internaionalStudent: '國際學生',
  internaionalStudentCount: '國際學生人数',
  universityAccreditation: '大學認證',
  termArrange:'學期安排',
  temperature: '平均溫度',
  schoolList: '學校列表',
  allCategory: '所有類型',
  allCity: '所有城市',
  allState: '所有州省',
  chooseCategory: '選擇類型',
  chooseCountry: '選擇國家',
  chooseCity: '選擇城市',
  chooseState: '選擇州省',
  addCharater: '新增特性',
  characterName: '特性名稱',
  characterValue: '特性值',
  pleaseInputCompletely: '請輸入完整',
  state: '州/省',
  subtitle: '副標題',
  all: '全部',
  title: '標題',
  defaultTitle: '默認標題',
  infoDisplayAreaSort : '資訊展現區域排序',
  adminAnnualSchoolList: '年會學校列表',
  adminAnnualSchoolDetail: '年會學校詳情',
  noUpdate: '暫未上傳',
  checkAttach: '查看附件',
  copyLink:'複製鏈接',
  opySuccess:'已復制鏈接，請另打開瀏覽器，進行文件下載',
  copyError:'複製失敗',
}