import { DirectiveBinding, DirectiveOptions } from 'vue/types/options';

const validateInput: DirectiveOptions = {
  inserted(el: HTMLElement, binding: DirectiveBinding) {
    if (
      el.classList.contains('el-input') ||
      el.classList.contains('el-input-number') ||
      el.classList.contains('el-select') ||
      el.classList.contains('el-textarea') ||
      el.classList.contains('el-input-number') ||
      el instanceof HTMLInputElement
    ){
      console.log(true)
    }
  }
};

export default validateInput;
