import { deepClone } from '@/utils';
import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import SchoolRouter from './school';
import AnnualSchool from './annualSchool'
import SystemManagement from './systemManagement'
import Setting from './setting'
Vue.use(VueRouter);
export type CusRouteConfig = {
  meta?: {
    name?: string;       // i18n的名字
    cus_name?: string;   // 自定义名字，大多数从后台获取时，后台给的名字或自己动态添加
    icon?: string;       // 图标，如果没有则是 ·
    query?: {[key: string]: string};         // 动态路由时赋值
    params?: {[key: string]: string};        // 带参数时的路由
    affix?: boolean;     // 是否可在navList中移除
    isNotPage?: boolean  // 当前路由是否是一个界面默认是的
    permission?: Array<string>  // 权限名称
    isNotAside?: boolean // 显示在侧边栏 默认显示在侧边栏
    isCommon?: boolean   // 是否是公共组件
  },
  children?: Array<CusRouteConfig>
}&RouteConfig;
export type CusRoute = {
  meta?: {
    name?: string;       // i18n的名字
    cus_name?: string;   // 自定义名字，大多数从后台获取时，后台给的名字或自己动态添加
    icon?: string;       // 图标，如果没有则是 ·
    query?: {[key: string]: string};         // 动态路由时赋值
    params?: {[key: string]: string};        // 带参数时的路由
    affix?: boolean;     // 是否可在navList中移除
    isNotPage?: boolean  // 当前路由是否是一个界面默认是的
    permission?: string  // 权限名称
    isNotAside?: boolean // 显示在侧边栏 默认显示在侧边栏
    isCommon?: boolean   // 是否是公共组件
  },
} & Route
const errorRoutes:CusRouteConfig =  {
  path: 'error',
  name: 'adminError',
  meta: {
    name: 'adminError',
    isNotAside: true,
    isCommon: true
  },
  component: () => import("../view/admin/error/error.vue")
}
export const asyncAdminRoutes: CusRouteConfig[] = [
  {
    path: 'index',
    name: 'adminIndex',
    meta: {
      name: 'index',
      icon: '&#xe602;',
      permission: ['homePage'],
      affix: true,
    },
    component: () => import("../view/admin/index.vue")
  },      // 首页
  AnnualSchool,
  SchoolRouter,
  SystemManagement,
]
export const commonAdminRoutes: CusRouteConfig[] = [
  Setting,
]
export const basicRoutes: Array<CusRouteConfig> = [
  {
    path: '/',
    redirect: '/schoolList',
  },
  {
    path: '/schoolList',
    name: 'userSchoolList',
    component: () => import('../view/index.vue')
  },
  {
    path: '/schoolList/:id',
    name: 'userSchoolListById',
    component: () => import('../view/index.vue')
  },
  {
    path: '/schoolDetail/:id',
    name: 'schooolDetail',
    component: () => import('../view/school_detail.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../layout/admin.vue'),
    redirect: '/admin/index',
    children: [],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import("../view/login.vue")
  },
  {
    path: '/loginto',
    name: 'loginto',
    component: () => import("../view/annual_login.vue")
  },
  {
    path: '/register',
    name: 'register',
    component: () => import("../view/register.vue")
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () => import("../view/forget_password.vue")
  },
  {
    path: '*',
    redirect : '/schoolList'
  }
]
// 降级成一级嵌套路由
export function formatRouter(routes: RouteConfig[], parentPath: string = '', newRoute: RouteConfig[] = []){
  for (let item of routes){
    let path = parentPath !== '' && parentPath !== '/' ? parentPath + '/' + item.path : parentPath+ item.path;
    item.path = path;
    if(item.redirect) {
      item.redirect = path + '/' + item.redirect;
    }
    newRoute.push(item);
    if(item.children && item.children.length > 0){
      formatRouter(item.children, path, newRoute);
      delete item.children;
    }
  }
  return newRoute;
}

export const getCommonAdminRoutes = function(): CusRouteConfig[]{
  return deepClone(commonAdminRoutes, [], ['component']);
}
export const getAsyncAdminRoutes = function(): CusRouteConfig[]{
  return deepClone(asyncAdminRoutes, [], ['component']);
}
export const getAllAdminRoutes = function(component: boolean = true): CusRouteConfig[]{
  const allAdminRoutes: CusRouteConfig[]= [];
  allAdminRoutes.push(...commonAdminRoutes, ...asyncAdminRoutes, errorRoutes)
  return deepClone(allAdminRoutes, [], [component? '': 'component']);
}
/**
 * 添加路由by权限
 * @isSupperPermission 超管？
 * @permission 拥有的权限列表
*/
export const addRouteByPermission = function(isSupperPermission: boolean, permission: string[]){
  let allAdminRoutes = formatRouter(getAllAdminRoutes());
  
  const tempPermission: {[key:string]:any} = {};
  for (let i = 0, lng = permission.length; lng > i; i++){
    tempPermission[permission[i]] = true;
  }
  for(let i = 0, lng = allAdminRoutes.length; lng > i; i++){
    if( isSupperPermission ){
      router.addRoute('admin', allAdminRoutes[i])
      continue;
    }
    const meta = allAdminRoutes[i].meta;

    if(meta && meta.permission && meta.permission.length && !meta.isCommon){
      // 若果meta有permission 且 含于我的权限 则添加到路由
      // router.addRoute('admin', allAdminRoutes[i])
      let filterPer = meta.permission.filter((item: string) =>{
        return tempPermission[item]
      })
      if(filterPer.length != 0){
        meta.permission = filterPer;
        router.addRoute('admin', allAdminRoutes[i])
      }
      continue;
    } else {
      // 如果路由没有设置权限名则也添加路由
      router.addRoute('admin', allAdminRoutes[i])
      continue;
    }
  }
}
export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher;
}
const createRouter = () => new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: basicRoutes,
});
const router = createRouter();
router.beforeEach((to: CusRoute, from: CusRoute, next: Function)=>{
  const has = router.getRoutes().find(item=>item.name == to.name);
  if (has){
    next();
  } else{
    if (to.name && to.name.indexOf('admin')>=0){
      next({name: 'adminError'})
    } else {
      next('/')
    }
  }
})
router.onError((err=>{
  
}))
export default router;