import { CusRouteConfig } from './index'

const systemManagement: CusRouteConfig = {
  path: 'systemManagement',
  name: 'adminsystemManagement',
  meta: {
    name: 'systemManagement',
    icon: '&#xe85f;',
    isNotPage: true,
    permission: ['systemConfigManagement']
  },
  children: [
    {
      path: 'userManagement',
      name: 'adminUserManagement',
      meta: {
        name: 'userManagement',
        permission: ['systemConfigUser', 'systemConfigUserView', 'systemConfigUserDelete', 'systemConfigUserAdd']
      },
      component: () => import("../view/admin/system/user_management.vue"),
      children: [
        {
          path: 'userAdd',
          name: 'adminUserAdd',
          meta: {
            name: 'userAdd',
            isNotAside: true,
            permission: ['systemConfigUserAdd']
          },
          component: () => import("../view/admin/system/user_detail.vue"),
        },
        {
          path: 'userDetail/:id',
          name: 'adminUserDetail',
          meta: {
            name: 'userDetail',
            isNotAside: true,
            permission: ['systemConfigUserView', 'systemConfigUserEdit']
          },
          component: () => import("../view/admin/system/user_detail.vue"),
        }
      ]
    },
    {
      path: 'userGroup',
      name: 'adminUserGroup',
      meta: {
        name: 'userGroup',
        permission: ['systemConfigUserGroup', 'systemConfigUserGroupView', 'systemConfigUserGroupAdd', 'systemConfigUserGroupEdit', 'systemConfigUserGroupDelete']
      },
      component: () => import("../view/admin/system/userGroup/user_group.vue"),
    }
  ]
};
export default systemManagement;