import { UserInfo } from '@/interface';
import request, { transformRequest } from '@/utils/request';

interface RegisterData {
  mobileAreaCode: string, 
  mobile: string, 
  fkPlatformType: string,
  isActive: boolean, 
  loginPs: string,
  verification: string,
  nickname: string,
  loginId: string,
}
/**
 * grant_type: Oauth认证模式（区分password和refresh_token等）
 * access_token: 登录请求返回的 token
 * refresh_token: 登录请求返回的刷新token，用于换取新的token，不用再输入账号密码
 * expires_in：token 的过期时间(秒)
 * scope:权限标识（暂时无用）
 * Authorization：客户端请求密钥（验证客户端是否合法）
 */
export interface LoginData {
  username: string,
  password: string,
  fkPlatformType: string,
  grant_type: string,
}
// 获取验证码
export function getVerification(data: {mobileAreaCode: string, mobile: string, fkPlatformType: string, type: 'register' | 'reset'}) {
  return request({
    url: '/appUser/verification',
    method: 'post',
    data
  })
}
export function register(data: RegisterData){
  return request({
    url: '/appUser/registra',
    method: 'post',
    data
  })
}

// 登录接口（新版本：2022/7/19）
export function userTokenLoginApi(params: LoginData) {
  return request({
    url: '/authentication/oauth/token',
    method: 'post',
    params
  })
}
// 登录后获取用户资料
export function getUserInfoApi() {
  return request({
    url: '/authentication/oauth/getUser',
    method: 'get',
    // headers: {
    //   'at': token // 认证客户端密钥
    // },
  })
}
// 刷新token 需要传grant_type:refresh_token，refresh_token:xxxxx
export function refreshTokenApi(data: any) {
  return request({
    url: '/authentication/oauth/token',
    method: 'post',
    data,
    transformRequest,
  })
}
// 退出登录
export function logout() {
  return request({
    url: '/authentication/oauth/logout',
    method: 'get'
  })
}

export function forgetPassword(data: {mobileAreaCode: string, mobile: string, verification: string, newLoginPs: string, fkPlatformType: string}){
  return request({
    url: '/appUser/modifyPwd',
    method: 'post',
    data
  })
}

export function resetPassword(data:{newLoginPs: string, loginPs: string, id: number|string}){
  return request({
    url: '/appUser/updatePwdByIb',
    method: 'post',
    data
  })
}

// 文件下载
export function fileDownload(data:any){
  return request({
    url: '/appFile/download',
    method: 'post',
    data,
    responseType:'blob',
  });
}
// 文件下载（公共）
export function downloadByPublicDownload(data:any){
  return request({
    url: '/appFile/downloadByPublic',
    method: 'post',
    data,
    responseType:'blob',
  });
}
// 不登陆得信息接口
export function getPubUrlApi(){
  return request({
    url: '/authentication/oauth/getPubUrl',
    method: 'get',
  })
}
export function getUserInfoById(id: number){
  return request({
    url: '/appUser/ibUserDetail?id='+id,
    method: 'get',
  })
}

export function createUser(data: UserInfo){
  return request({
    url: '/appUser/ibAdd',
    method: 'post',
    data
  })
}
export function updateUser(data: UserInfo){
  return request({
    url: '/appUser/ibUserUpdate',
    method: 'post',
    data
  })
}

export function checkMobile(mobile: string, id: number | string = ''){
  return request({
    url: '/appUser/checkMobile?mobile='+mobile+'&id='+id,
    method: 'get'
  })
}

export function checkLoginId(loginId: string, id: number | string = ''){
  return request({
    url: '/appUser/checkLoginId?loginId='+loginId+'&id='+id,
    method: 'get'
  })
}