import Vue from 'vue'
import VueI18n from 'vue-i18n';

// import elementEnLocale from 'element-ui/lib/locale/lang/en';
// import elementZhCnLocale from 'element-ui/lib/locale/lang/zh-CN.js';
// import elementZhTwLocale from 'element-ui/lib/locale/lang/zh-TW.js';

import enLocale from './en/index';
import zhCnLocale from './zh_cn/index';
import zhTwLocale from './zh_tw/index';

Vue.use(VueI18n);

const messages: {[key:string]:any} = {
  'en': {
    ...enLocale,
  },
  'zh-cn': {
    ...zhCnLocale,
  },
  'zh-tw': {
    ...zhTwLocale,
  },
};

const languageKey = 'language';
export enum LanguageType {
  "zh_cn" = "zh-cn",
  "zh_tw" = "zh-tw",
  "en" = "en",
}

export const getLanguage = () => localStorage.getItem(languageKey);
export const setLanguage = (language: string) => localStorage.setItem(languageKey, language);
export const getLocale = () => {
  const cookieLanguage = getLanguage();
  if (cookieLanguage) {
    return cookieLanguage;
  }
  const language = navigator.language.toLowerCase();
  if (messages[language]) {
    setLanguage(language);
    return language;
  }
  return 'zh-cn';
};

export default new VueI18n({
  locale: getLocale(),
  messages,
});