import { addRouteByPermission } from '@/router';
import { LayoutStoreModule } from '@/store/LayoutModule';

const sidebarStatusKey = 'sidebar_status';
export const getSidebarStatus = () => localStorage.getItem(sidebarStatusKey);
export const setSidebarStatus = (sidebarStatus: string) => localStorage.setItem(sidebarStatusKey, sidebarStatus);

const languageKey = 'language';
export const getLanguage = () => localStorage.getItem(languageKey);
export const setLanguage = (language: string) => localStorage.setItem(languageKey, language);

const sizeKey = 'size';
export const getSize = () => localStorage.getItem(sizeKey);
export const setSize = (size: string) => localStorage.setItem(sizeKey, size);


export function setInitial(){
  let permission: any = localStorage.getItem('ib_permission');
  let isSupperPermission: any = localStorage.getItem('ib_isSupperPermission');
  let schoolList: any = localStorage.getItem('ib_schoolList');
  permission = permission === 'undefined' ? '[]' : permission;
  schoolList = schoolList === 'undefined' ? '[]' : schoolList;
  if (permission && isSupperPermission && schoolList){
    permission = JSON.parse(permission);
    isSupperPermission = JSON.parse(isSupperPermission) === true;
    schoolList = JSON.parse(schoolList);
    addRouteByPermission(isSupperPermission, permission)
    LayoutStoreModule.setMenuAside({isSupperPermission, permission, schoolList})
  }
}

// 登录最长多久需要刷新token
const expiresInKey = 'ib_expires_in';
export const getExpiresIn = () => localStorage.getItem(expiresInKey);
export const setExpiresIn = (expiresIn: string) => localStorage.setItem(expiresInKey, expiresIn);

// 登录时间
const loginTimeKey='ib_login_time';
export const getLoginTime = () => localStorage.getItem(loginTimeKey);
export const setLoginTime = (loginTime: string) => localStorage.setItem(loginTimeKey, loginTime);

// 存储数据
const resourceKey = 'ib_permission';
export const getPermission = () => localStorage.getItem(resourceKey);
export const SetPermission = (permission: any) => localStorage.setItem(resourceKey, permission);

const supperPermissionKey = 'ib_isSupperPermission';
export const getIsSupperPermission = () => localStorage.getItem(supperPermissionKey);
export const SetIsSupperPermission = (supperPermission: any) => localStorage.setItem(supperPermissionKey, supperPermission);

const schoolListKey = 'ib_schoolList';
export const getSchoolList = () => localStorage.getItem(schoolListKey);
export const SetSchoolList = (school: any) => localStorage.setItem(schoolListKey, school);

export function exitInitial(){
  localStorage.removeItem('ib_permission');
  localStorage.removeItem('ib_isSupperPermission');
  localStorage.removeItem('ib_schoolList');
  localStorage.removeItem('ib_expires_in')
  localStorage.removeItem('ib_login_time')
  localStorage.removeItem('ib_permission')
  localStorage.removeItem('ib_isSupperPermission')
  localStorage.removeItem('ib_schoolList')
}