


















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {fileDownload} from '@/api/user_api';
@Component({
  name: 'buttonAllBox',
})
export default class extends Vue {
  @Prop({ default: -1 }) scopeIdx?: number; // 表格每行的下标值 - 用于上下移按钮是否禁止点击
  @Prop() datasLen?: number; // 表格当前页数据最大下标值，用于判断下移按钮禁止
  // 按钮类型
  // 上移
  @Prop({ default: false }) showMoveUp?: boolean;
  // 下移
  @Prop({ default: false }) showMoveDown?: boolean;
  // 编辑
  @Prop({ default: false }) showEdit?: boolean;
  // 删除
  @Prop({ default: false }) showRemove?: boolean;
  // 查看
  @Prop({ default: false }) showCheck?: boolean;
  // 下载
  @Prop({ default: false }) download?: boolean;
  // 自定义下载
  @Prop({ default: false}) customeDownload?: boolean;
  // 路径
  @Prop({ default: '' }) filePath?: string;
  // 名称
  @Prop({ default: '' }) fileNameOrc?:string;

  handleMoveUp() {
    this.$emit('handleMoveUp');
  }
  handleMoveDown() {
    this.$emit('handleMoveDown');
  }
  handleEdit() {
    this.$emit('handleEdit');
  }
  handleRemove() {
    this.$emit('handleRemove');
  }
  handleCheck() {
    this.$emit('handleCheck');
  }
  handleDownload(){
    this.$emit('handleDownload')
  }

  // 下载附件
  fileDownloadFun(){
    if( this.customeDownload ){
      return this.handleDownload();
    }
    const data={
      fileNameOrc:this.fileNameOrc,
      fileKey:this.filePath
    }
    fileDownload(data).then((res:any)=>{
      if(res){
        const blob = new Blob([res.data]);
        const elink = document.createElement('a');
        elink.download = (this.fileNameOrc as string);
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      }
    })
  }
}
