import { Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import store from '@/store'
import router, { resetRouter } from '@/router';
import { MyGroup, SchoolSelect, UserInfo } from '@/interface';
import { logout, LoginData, userTokenLoginApi, getUserInfoApi, refreshTokenApi } from '@/api/user_api';
import { exitInitial, setExpiresIn, setLoginTime, SetPermission, SetIsSupperPermission, SetSchoolList } from '@/utils/local_storage';
import { LayoutStoreModule } from './LayoutModule';
import { Message } from 'element-ui';
import {
  getToken,
  setToken,
  removeToken,
  getRefreshToken,
  setRefreshToken,
  removeRefreshToken } from '@/utils/session_storage'
@Module({
  store,
  name: 'UserModule',
  dynamic: true,
  namespaced: true
})
export default class UserModule extends VuexModule{
  userInfo: UserInfo = {
    id: 0,
    isActive: false,
    loginPs: '',
    loginId: '',
    mobile: '',
    num: ''
  }
  myPermission: string[] = [];
  isSupperPermission: boolean = false;
  mySchool: SchoolSelect[] = [];
  myGroup: MyGroup[] = [];
  accessToken:string= getToken() || '';
  refreshToken:string= getRefreshToken()||'';
  headers = {
    fkPlatformType: 'get_ib',
    Authorization: 'Basic aWJDZW50ZXI6aWJDZW50ZXI=',
    at: getToken() || ''
  }
  get isLogin(){
    return Boolean(this.userInfo.id);
  }
  // 如果是年会成员
  get isAnnualMember(){
    if(this.isSupperPermission) return false;
    if(!this.myGroup || !this.myGroup.length) return false;
    return (this.myGroup.findIndex(item=>item.groupKey == 'IB_USER_GROUP_GEA_ANNUAL') >= 0)
  }
  
  @Mutation
  setUserInfo(userInfo: UserInfo){
    this.userInfo = userInfo
  }
  @Mutation
  setPermission(permission: string[]){
    this.myPermission = permission;
  }
  @Mutation
  setSupperPermission(bol: boolean){
    this.isSupperPermission = bol;
  }
  @Mutation
  setMySchool(school: SchoolSelect[]){
    this.mySchool = school;
  }
  @Mutation
  resetUserInfo(){
    const isAnnualMember = this.myGroup.findIndex(item=>item.groupKey == 'IB_USER_GROUP_GEA_ANNUAL') >= 0;
    if(router.app.$route.name == 'adminIndex' ) {
      router.push(isAnnualMember?'/loginto' : '/login');
    } else if (router.app.$route.name && router.app.$route.name.indexOf('admin') >= 0){
      router.push({name:'adminIndex'}).then(()=>{
        router.push(isAnnualMember?'/loginto' : '/login');
      })
    }
    this.userInfo = {
      id: 0,
      isActive: false,
      loginPs: '',
      loginId: '',
      mobile: '',
      num: ''
    };
    this.myPermission = [];
    this.isSupperPermission = false;
    this.mySchool = [];
    this.myGroup = [];
    this.accessToken = '';
    this.refreshToken = '';
    this.headers.at = '';
    
    setTimeout(()=>{
      exitInitial();
      removeToken();
      removeRefreshToken();
      resetRouter();
      LayoutStoreModule.initNavItem();
    })
    
  }
  @Mutation 
  initUserInfo(data: {userInfo: UserInfo, school:SchoolSelect[], isSupperPermission: boolean, permission: string[], myGroup: MyGroup[]} | undefined){
    if(data){
      this.userInfo = data.userInfo;
      this.mySchool = data.school;
      this.isSupperPermission = data.isSupperPermission;
      this.myPermission = data.permission;
      this.myGroup = data.myGroup;
    }
  }
  // 设置登录token
  @Mutation
  setAccessTokenData(token?: string) {
    this.accessToken = token ? token: ''
    this.headers.at = token ? token: ''
  }
  // 设置刷新token
  @Mutation
  setRefreshTokenData(token?: string) {
    this.refreshToken = token ? token: ''
  }
  @Action({
    commit: 'initUserInfo'
  })
  async getUserData() {
    const userData: any = await getUserInfoApi();
    if (!userData.data) {
      throw Error('Verification failed, please Login again.');
    }
    // 失效令牌
    if(userData.data.code === '0006') {
      Message.error('login timeout')
      this.resetUserInfo();
      return
    }
    const {userGroupDtoList, resourceKeys, institution, isAdmin} = userData.data.data;
    SetPermission(JSON.stringify(resourceKeys || []));
    SetIsSupperPermission(JSON.stringify(isAdmin));
    SetSchoolList(JSON.stringify(institution || []));
    const newUser = Object.assign({id: userData.data.data.staffId}, userData.data.data);
    UserStoreModule.setUserInfo(newUser);
    return {
      userInfo: newUser,
      school: institution || [],
      isSupperPermission: isAdmin,
      permission: resourceKeys || [],
      myGroup: userGroupDtoList || [],
    }
  }
  @Action
  async userLogin(data: LoginData){
    try {
      const ret: any = await userTokenLoginApi(data);
      if (ret.status === 200 && ret.data.access_token){
        const { access_token, refresh_token, expires_in, login_time } = ret.data;
        setToken(access_token);
        setRefreshToken(refresh_token);
        setExpiresIn(expires_in);
        setLoginTime(login_time);
        this.setAccessTokenData(access_token);
        this.setRefreshTokenData(refresh_token);
        await this.getUserData()
      }
    } catch (e){
      return undefined;
    }
  }
  @Action
  async checkLogin(){
    try {
      const sendData: any = {
        grant_type: 'refresh_token',
        refresh_token: this.refreshToken,
      }
      const ret = await refreshTokenApi(sendData);
      if(ret.status == 200 && ret.data.access_token){
        const { access_token, refresh_token, expires_in, login_time } = ret.data;
        setToken(access_token);
        setRefreshToken(refresh_token);
        setExpiresIn(expires_in);
        setLoginTime(login_time);
        this.setAccessTokenData(access_token);
        this.setRefreshTokenData(refresh_token);
        await UserStoreModule.getUserData()
      } else {
        Message.error('login timeout')
        this.resetUserInfo();
      }
    } catch(e){
      Message.error('login timeout')
      this.resetUserInfo();
    }
  }
  @Action({
    commit: 'resetUserInfo'
  })
  async userLogout(){
    try {
      await logout();
    // eslint-disable-next-line no-empty
    } catch (e) {
    }
    return '';
  }
}
export const UserStoreModule = getModule(UserModule);