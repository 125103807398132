import element from './element';
const route = {
  index: 'User homepage',
  schoolList: 'school',
  schoolDetail: 'School Details',
  setting: 'settings',
  systemManagement: 'System Management',
  userManagement: 'User Account Management',
  userGroup: 'User Group',
  userAdd: 'Add user',
  userDetail: 'User Details',
  adminError: 'error'
}
const requestError = {
  error_0001: 'Missing parameter',
  error_0002: 'Invalid parameter',
  error_0003: 'Invalid signature',
  error_0004: 'Authentication failed',
  error_0005: 'Service unavailable',
  error_0006: 'The session has expired',
  error_0007: 'No permission',
  error_1001: 'Access forbidden',
  error_1002: 'Request error',
  error_1003: 'The request was successful',
  error_1004: 'Server Error',
  error_1005: 'Error accessing external service',
  error_1006: 'Gateway timed out',
  error_1007: 'feign call failed',
}
export default {
  ...element,
  ...route,
  ...requestError,
  systemName: 'Information Board',
  user: 'User',
  pleaseInputAccount: 'Please enter the account number',
  pleaseInputPhoneNum: 'Please input the phone number',
  pleaseInputPwd: 'Please input the password',
  pleaseInputAgain: 'Please confirm the password',
  login: 'login',
  register: 'register',
  welcomeLogin: 'Welcome to login',
  noHaveAccount: 'No account yet?',
  haveAccount: 'Already have an account?',
  registerInstantly: 'Register now',
  loginInstantly: 'Login now',
  register_login_tip: 'Please fill in the 8-12-digit password, including uppercase and lowercase letters and legal special symbols',
  two_password_not_equal: 'The password entered twice is incorrect',
  position: 'position',
  changePassword: 'Change password',
  searchKeyWord: 'Enter a keyword to search',
  search: 'Search',
  create: 'create',
  preview: 'Preview',
  opera: 'Operation',
  welcomeTo: 'Welcome to',
  edit: 'edit',
  delete: 'delete',
  updatingFiles:'Upload',
  uploadCover: 'Upload school cover',
  desc: 'description',
  uploadAttachment: 'Upload attachment',
  uploadInstructions: 'Attachment upload instructions',
  instructionsOne: 'Type: system attachment upload supported file formats are: doc, docx, xls, xlsx, ppt, pptx, pdf, mov, mp4',
  instructionsTwo: 'Size: The upload file size limit is less than 50M. ',
  imageLimit: 'Support GIF, JPG, PNG type files, the size of each image is limited to 5k-2M',
  failUploadTip: 'The current format cannot be uploaded, please pay attention to the upload prompt!',
  typeIsEmpty: 'Please select a type',
  remarkIsEmpty: 'Please fill in the remarks',
  fileNameIsEmpty: 'Please upload attachments',
  submit: 'Submit',
  underSubmission: 'Unsubmitted',
  save: 'Save',
  cancel: 'Cancel',
  schoolInfo: 'School Information',
  add: 'Add',
  forgetPassword: 'forgot password',
  getCode: 'Get verification code',
  code: 'Verification code',
  confirmRegister: 'Confirm registration',
  phoneRegion: 'Region number',
  pleaseSelectPhoneRegion: 'Please select the region number',
  errorPhoneNumberInRegion: 'The phone number format of the current area code is incorrect',
  pleaseInputCode: 'Please enter the verification code',
  accountValidateTip: 'Only numbers, English, Chinese, underscores are allowed',
  confirmResetPasswod: 'Confirm to change password',
  privatePersonalInfo: 'Personal information',
  oldPassword: 'old password',
  newPassword: 'New password',
  confirmNewPassword: 'Confirm new password',
  pleaseInput: 'Please input',
  pleaseInputPersonalInfoCompletely: '(Please fill in complete personal information)',
  userName: 'Name',
  userEnglishName: 'English name',
  gender: 'gender',
  birthday: 'date of birth',
  pleaseSelectBirthday: 'Select Date',
  institution: 'Affiliated company/organization',
  companyPosition: 'Position',
  mobilePhone: 'Mobile Phone',
  wechat: 'WeChat',
  zipCode: 'zip code',
  contactAddress: 'contact address',
  addressDetail: 'Detailed address',
  male: 'male',
  female: 'female',
  pleaseChooseCountryProvinceCity: 'Choose country/state province/city',
  inputKeyword: 'input information search',
  cancelEdit: 'Cancel editing',
  cancelClose: 'Cancel close',
  allCountry: 'All countries',
  totalPage: 'Total {total} records, page {currentPage}/{pageTotal}',
  attachment: 'attachment',
  attachmentDesc: 'Attachment Description',
  schoolAttachUpload: 'School attachment upload',
  editSchoolCover: 'Edit school cover',
  reset: 'reset',
  refresh: 'Refresh',
  userId: 'User ID',
  loginAccount: 'Login account',
  loginPassword: 'login password',
  confirmPassword: 'Confirm password',
  createPerson: 'Creator',
  createTime: 'create time',
  bascalInfo: 'Basic information',
  hightSetting: 'Advanced Settings',
  pleaseSelect: 'Please select',
  systemAutoCreate: 'System automatically generated',
  confirmUpdate: 'Confirm update',
  accountIsActive: 'Is the account active',
  yes: 'Yes',
  no: 'No',
  phoneNum: 'Phone number',
  resourceNum: "Number of resources",
  personNum: "Number of Persons",
  changePerson: "Modify Person",
  changeTime: "Modification time",
  moveUp: 'Move up',
  moveDown: 'Move down',
  resourceAllocation: 'Configure resources',
  allUser: 'All users',
  currentUser: 'Current user',
  pleaseSelectUserGroup: 'Please select a user group',
  error: 'error',
  country: 'country',
  nature: 'school nature',
  studentCount: 'Number of students',
  schoolName: 'School name',
  website: 'official website',
  pdfSize: 'Number of attachments',
  schoolNameChn: 'School Chinese name',
  establishedDate: 'established time',
  checkTime: 'Please select a date and time',
  tip: 'tip',
  sthSuccess: '{sth}success',
  sthFail: '{sth} failed',
  nameEmpty: 'Please fill in the school name',
  nameChnEmpty: 'Please fill in the Chinese name of the school',
  natureEmpty: 'Please fill in the nature of the school',
  studentCountEmpty: 'Please fill in the number of students',
  websiteEmpty: 'Please fill in the official website',
  countrySelectEmpty: 'Please select a country',
  establishedDateEmpty: 'Please select the establishment time',
  schoolWebsite: 'School official website',
  editSuccess: 'Edit success',
  addSuccess: 'Add success',
  editFail: 'Edit failed',
  addFail: 'Add failed',
  publicSchool: 'public',
  privateSchool: 'private',
  deleteConfirmMsg: 'Cannot be restored after deletion, confirm to delete this {name}? ',
  data:'data',
  confirm: 'OK',
  delSucc: 'Delete successful! ',
  delFail: 'Delete failed! ',
  delCancel: 'Delete Cancel! ',
  operaConfirmTips: 'Operation Confirmation',
  uploadType: 'Upload Type',
  schoolPicture: 'School environment picture',
  schoolFile: 'School Attachment',
  afterSecond: '{second}s later',
  exit: 'Log out',
  privatePersonalInfoUpdate: 'Personal information modification',
  pleaseInputNickname: 'Please enter a nickname',
  loginTimeout: 'Login timeout',
  errorMsg: 'Error message',
  reLogin: 'Relogin',
  groupKey: 'Group Key',
  groupName:'group name',
  viewOrder:'sort',
  schoolInfoType: 'School information type',
  schoolInfoTypeEmpty: 'Please select a school information type',
  descEmpty: 'Please fill in the description type',
  saveSuss: 'Save successfully',
  saveFail: 'Save failed',
  uploadSuccess: 'Upload successful',
  checkUrl: 'Please enter a valid URL',
  showImg: 'Picture display',
  close: 'close',
  serverError: 'Server error',
  errorCallAdmin:'Unknown errors, please contact the administrator',
  downloadAttach: 'Download attachment',
  pleaseSelectState: 'Please select a state',
  pleaseSelectCity: 'Please select a city',
  userDetailUpdate: 'User information modification',
  pleseUploadSchoolCover: 'Please upload the school cover',
  type: 'type',
  check: 'check',
  schoolProfile: 'School Profile',
  schoolAdvatanges: 'Characteristic advantages',
  schoolAddress: 'School location',
  schoolIntroductionVideo: 'School introduction video',
  schoolAdmission: 'Tuition and admission requirements',
  videoTip: '(click to watch the school video)',
  builderYear: 'Founding year',
  pepole: 'person',
  schoolType: 'School Type',
  schoolFileDownload: 'Related attachment download',
  UploadBackgroundImage: 'Upload background image',
  backgroundImageLimit: 'Support GIF, JPG, PNG type files, the size of each image is limited to 5k-500K',
  backgroundImgTip: 'It is recommended to upload a high-definition background image of 1920 × 1080px',
  attachmentFileTip: 'Type: file format and size limit supported by system upload:',
  attachmentFileTipOne: '1. Attachment upload: doc, docx, xls, xlsx, ppt, pptx, pdf; the size of each file is limited to less than 50M. ',
  attachmentFileTipTwo: '2. Image upload: png, gif, jpg; the size of each image is limited to 5k-500K. ',
  attachmentFileTipThree: '3. Video upload: mov, mp4; the size of each video is limited to less than 50M. ',
  detailsBackgroundImage: 'Details background image',
  LocationBackgroundMap: 'Location background map',
  coverUploadInstructions: 'School cover upload instructions',
  welcome: 'Welcome',
  coverImageEmpty: 'No image uploaded yet',
  schoolListEmpty: 'The keyword does not match the school yet',
  register_account_tip: 'Please fill in a 3-20-digit account number that only includes uppercase, lowercase letters and numbers',
  pleaseInputPhoneNumOrAccount: 'Please enter the phone number/account number',
  accountExited: 'The account already exists',
  mobileExited: 'Mobile number already exists',
  mobileExitedTip: 'Dear user, the mobile phone number you entered has been registered and used on our [{platform}] platform, and you can log in with your mobile phone number + password. If you forget your password, please click on the Forgot Password below to modify it, thank you for your cooperation! ',
  updateSuccess: 'Modification succeeded',
  city: 'city',
  internationalStudent: 'International student',
  internationalStudentCount: 'Number of international students',
  universityAccreditation: 'University Accreditation',
  termArrange: 'Term Arrangement',
  temperature: 'Average temperature',
  schoolList: 'School List',
  allCategory: 'All types',
  allCity: 'All cities',
  allState: 'All states and provinces',
  chooseCategory: 'Choose type',
  chooseCountry: 'Choose country',
  chooseCity: 'Choose a city',
  chooseState: 'Choose state and province',
  addCharater: 'Add new features',
  characterName: 'character name',
  characterValue: 'Characteristic value',
  pleaseInputCompletely: 'Please input complete',
  state: 'state/province',
  subtitle: 'Subtitle',
  all: 'all',
  title: 'Title',
  defaultTitle: 'default title',
  infoDisplayAreaSort : 'Information display area sorting',
  adminAnnualSchoolList: 'Annual school list',
  adminAnnualSchoolDetail: 'Annual school details',
  noUpdate: 'Not uploaded yet',
  checkAttach: 'check attachment',
  copyLink:'copy Link',
  copySuccess:'The link has been copied, please open another browser to download the file',
  copyError:'replication failed',
}