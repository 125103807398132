import element from './element';
const route = {
  index: '用户主页',
  schoolList: '学校',
  schoolDetail: '学校详情',
  setting: '设置',
  systemManagement: '系统管理',
  userManagement: '用户账号管理',
  userGroup: '用户组',
  userAdd: '新增用户',
  userDetail: '用户详细',
  adminError: '错误'
}
const requestError = {
  error_0001: '缺少参数',
  error_0002: '无效参数',
  error_0003: '无效签名',
  error_0004: '验证失败',
  error_0005: '服务不可用',
  error_0006: '会话已失效',
  error_0007: '没权限',
  error_1001: '禁止访问',
  error_1002: '请求错误',
  error_1003: '请求成功',
  error_1004: '服务器错误',
  error_1005: '访问外部服务错误',
  error_1006: '网关超时',
  error_1007: 'feign调用失败',
}
export default {
  ...element,
  ...route,
  ...requestError,
  systemName: 'Information Board',
  user: '用户',
  pleaseInputAccount: '请输入账号',
  pleaseInputPhoneNum: '请输入手机号',
  pleaseInputPwd: '请输入密码',
  pleaseInputAgain: '请确认密码',
  login: '登录',
  register: '注册',
  welcomeLogin: '欢迎登录',
  noHaveAccount: '还没有账号?',
  haveAccount: '已经有账号?',
  registerInstantly: '立即注册',
  loginInstantly: '立即登录',
  register_login_tip: '请填写8-12位密码，需包含大小写字母及合法特殊符号',
  two_password_not_equal: '两次输入的密码不正确',
  position: '位置',
  changePassword: '修改密码',
  searchKeyWord: '输入关键字搜索',
  search: '搜索',
  create: '创建',
  preview: '预览',
  opera: '操作',
  welcomeTo: '欢迎进入',
  edit: '编辑',
  delete: '删除',
  updatingFiles:'上传',
  uploadCover: '上传学校封面',
  desc: '描述',
  uploadAttachment:'上传附件',
  uploadInstructions:'附件上传说明',
  instructionsOne: '类型：系统附件上传支持的文件格式有：doc，docx，xls，xlsx，ppt，pptx，pdf, mov, mp4',
  instructionsTwo: '大小：上传文件大小限制50M以下。',
  imageLimit: '支持GIF、JPG、PNG类型的文件，每个图片大小限制为5k-2M',
  failUploadTip:'当前格式无法上传，请留意上传提示!',
  typeIsEmpty:'请选择类型',
  remarkIsEmpty:'请填写备注',
  fileNameIsEmpty:'请上传附件',
  submit: '提交',
  underSubmission: '未提交',
  save: '保存',
  cancel: '取消',
  schoolInfo: '学校资料',
  add: '新增',
  forgetPassword: '忘记密码',
  getCode: '获取验证码',
  code: '验证码',
  confirmRegister: '确定注册',
  phoneRegion: '区域号',
  pleaseSelectPhoneRegion: '请选择区域号',
  errorPhoneNumberInRegion: '当前区域号的手机号码格式不正确',
  pleaseInputCode: '请输入验证码',
  accountValidateTip: '只允许数字，英文，中文，下划线',
  confirmResetPasswod: '确定修改密码',
  privatePersonalInfo: '个人信息',
  oldPassword: '旧密码',
  newPassword: '新密码',
  confirmNewPassword: '确定新密码',
  pleaseInput: '请输入',
  pleaseInputPersonalInfoCompletely: '（请填写完整的个人信息）',
  userName: '姓名',
  userEnglishName: '英文名',
  gender: '性别',
  birthday: '出生日期',
  pleaseSelectBirthday: '选择日期',
  insititution: '所属公司/机构',
  componeyPosition: '所属职位',
  mobilePhone: '移动电话',
  wechat: '微信',
  zipCode: '邮编',
  contactAddress: '联系地址',
  addressDetail: '详细地址',
  male: '男',
  female: '女',
  pleaseChooseCountryProvinceCity: '选择国家/州省/城市',
  inputKeyword: '输入信息搜索',
  cancelEdit: '取消编辑',
  cancelClose: '取消关闭',
  allCountry: '全部国家',
  totalPage: '共{total}条记录，第{currentPage}/{pageTotal}页',
  attachment: '附件',
  attachmentDesc: '附件描述',
  schoolAttachUpload: '学校附件上传',
  editSchoolCover: '编辑学校封面',
  reset: '重置',
  refresh: '刷新',
  userId: '用户编号',
  loginAccount: '登录账号',
  loginPassword: '登录密码',
  confirmPassword: '确定密码',
  createPerson: '创建人',
  createTime: '创建时间',
  bascalInfo: '基本信息',
  hightSetting: '高级设置',
  pleaseSelect: '请选择',
  systemAutoCreate: '系统自动生成',
  confirmUpdate: '确定更新',
  accountIsActive: '账号是否激活',
  yes: '是',
  no: '否',
  phoneNum: '手机号',
  resourceNum: "资源数",
  personNum: "人员数",
  changePerson: "修改人",
  changeTime: "修改时间",
  moveUp: '上移',
  moveDown: '下移',
  resourceAllocation: '配置资源',
  allUser: '所有用户',
  currentUser: '当前用户',
  pleaseSelectUserGroup: '请选择用户组',
  error: '错误',
  country: '国家',
  nature: '学校性质',
  studentCount: '学生人数',
  schoolName: '学校名称',
  website: '官网',
  pdfSize: '附件数',
  schoolNameChn: '学校中文名称',
  establishedDate: '成立时间',
  checkTime: '请选择日期时间',
  tip:'提示',
  sthSuccess: '{sth}成功',
  sthFail: '{sth}失败',
  nameEmpty: '请填写学校名称',
  nameChnEmpty: '请填写学校中文名称',
  natureEmpty: '请填写学校性质',
  studentCountEmpty: '请填写学生人数',
  websiteEmpty: '请填写官网',
  countrySelectEmpty: '请选择国家',
  establishedDateEmpty: '请选择成立时间',
  schoolWebsite: '学校官网',
  editSuccess: '编辑成功',
  addSuccess: '新增成功',
  editFail: '编辑失败',
  addFail: '新增失败',
  publicSchool: '公立',
  privateSchool: '私立',
  deleteConfirmMsg: '删除后不能恢复，确认删除该{name}？',
  data:'数据',
  confirm: '确定',
  delSucc: '删除成功！',
  delFail: '删除失败！',
  delCancel: '删除取消！',
  operaConfirmTips: '操作确认',
  uploadType: '上传类型',
  schoolPicture: '学校环境图',
  schoolFile: '学校附件',
  afterSecond: '{second}s后',
  exit: '退出登录',
  privatePersonalInfoUpdate: '个人信息修改',
  pleaseInputNickname: '请输入昵称',
  loginTimeout: '登录超时',
  errorMsg: '错误信息',
  reLogin: '重新登录',
  groupKey:'组别Key',
  groupName:'组别名称',
  viewOrder:'排序',
  schoolInfoType: '学校资讯类型',
  schoolInfoTypeEmpty: '请选择学校资讯类型',
  descEmpty: '请填写描述类型',
  saveSuss: '保存成功',
  saveFail: '保存失败',
  uploadSuccess: '上传成功',
  checkUrl: '请输入合法的网址',
  showImg:'图片展示',
  close: '关闭',
  serverError: '服务器出错',
  errorCallAdmin:'未知错误，请联系管理员',
  downloadAttach: '下载附件',
  pleaseSelectState: '请选择州省',
  pleaseSelectCity: '请选择城市',
  userDetailUpdate: '用户信息修改',
  pleseUploadSchoolCover: '请上传学校封面',
  type: '类型',
  check: '查看',
  schoolProfile: '学校概要',
  schoolAdvatanges: '特色优势',
  schoolAddress: '学校地理位置',
  schoolIntroductionVideo: '学校介绍视频',
  schoolAdmission: '学费及入学要求',
  videoTip: '（点击观看学校视屏）',
  builderYear: '创校年份',
  pepole: '人',
  schoolType: '学校类型',
  schoolFileDownload: '相关附件下载',
  UploadBackgroundImage: '上传背景图片',
  backgroundImageLimit: '支持GIF、JPG、PNG类型的文件，每个图片大小限制为5k-500K',
  backgroundImgTip: '建议上传1920 × 1080px的高清背景图片',
  attachmentFileTip: '类型：系统上传支持的文件格式和大小限制：',
  attachmentFileTipOne: '1、附件上传：doc，docx，xls，xlsx，ppt，pptx，pdf；每个文件大小限制50M以下。',
  attachmentFileTipTwo: '2、图片上传：png，gif，jpg；每个图片大小限制为5k-500K。',
  attachmentFileTipThree: '3、视频上传：mov，mp4；每个视频大小限制为限制50M以下。',
  detailsBackgroundImage: '详情背景图',
  LocationBackgroundMap: '地理位置背景图',
  coverUploadInstructions: '学校封面上传说明',
  welcome: '欢迎',
  coverImageEmpty: '暂无上传图片',
  schoolListEmpty: '关键字暂无匹配到学校',
  register_account_tip: '请填写只包括字母大小写和数字的3-20位账号',
  pleaseInputPhoneNumOrAccount: '请输入手机号/账号',
  accountExited: '账号已存在',
  mobileExited: '手机号码已存在',
  mobileExitedTip: '尊敬的用户您好，您输入的手机号已经在我们旗下的【{platform}】平台进行注册使用，您可以通过手机号+密码进行登录。若忘记密码，请点击下面的忘记密码进行修改，谢谢您的配合！',
  updateSuccess: '修改成功',
  city: '城市',
  internaionalStudent: '国际学生',
  internaionalStudentCount: '国际学生人数',
  universityAccreditation: '大学认证',
  termArrange: '学期安排',
  temperature: '平均温度',
  schoolList: '学校列表',
  allCategory: '所有类型',
  allCity: '所有城市',
  allState: '所有州省',
  chooseCategory: '选择类型',
  chooseCountry: '选择国家',
  chooseCity: '选择城市',
  chooseState: '选择州省',
  addCharater: '新增特性',
  characterName: '特性名称',
  characterValue: '特性值',
  pleaseInputCompletely: '请输入完整',
  state: '州/省',
  subtitle: '副标题',
  all: '全部',
  title: '标题',
  defaultTitle: '默认标题',
  infoDisplayAreaSort : '资讯展现区域排序',
  adminAnnualSchoolList: '年会学校列表',
  adminAnnualSchoolDetail: '年会学校详情',
  noUpdate: '暂未上传',
  checkAttach: '查看附件',
  copyLink:'复制链接',
  copySuccess:'已复制链接，请另打开浏览器，进行文件下载',
  copyError:'复制失败',
}