import { CusRouteConfig } from './index'

const setting: CusRouteConfig = {
  path: 'setting',
  name: 'adminSetting',
  redirect: 'myInfo',
  meta: {
    name: 'setting',
    icon: '&#xe644;',
    isNotPage: true,
    isNotAside: true,
    isCommon: true,
    permission: ['adminSetting'],
  },
  children: [
    {
      path: 'resetPassword',
      name: 'adminResetPassword',
      meta: {
        name: 'changePassword',
        isNotAside: true,
        isCommon: true,
        permission: ['adminResetPassword'],
      },
      component: () => import("../view/admin/setting/reset_password.vue")
    },
    {
      path: 'myInfo',
      name: 'adminMyInfo',
      meta: {
        name: 'privatePersonalInfo',
        isCommon: true,
        isNotAside: true,
        permission: ['adminMyInfo'],
      },
      component: () => import("../view/admin/setting/my_info.vue")
    }
  ]
};
export default setting;