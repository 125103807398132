// User
import jsCookie from 'js-cookie';

// 登录token
const accessTokenKey = 'ib_access_token';
// 刷新token
const refreshTokenKey='ib_refresh_token';
// 登录token: 用cookie
export const getToken = () => jsCookie.get(accessTokenKey);
export const setToken = (token: string) => jsCookie.set(accessTokenKey, token);
export const removeToken = () => jsCookie.remove(accessTokenKey);
// 刷新token: 用缓存
export const getRefreshToken = () => sessionStorage.getItem(refreshTokenKey);
export const setRefreshToken = (token: string) => sessionStorage.setItem(refreshTokenKey, token);
export const removeRefreshToken = () => sessionStorage.removeItem(refreshTokenKey);
