import Vue from 'vue'
import App from './App.vue'
import Router from './router'
import Store from './store'
import VI18n from './lang'
import './utils/element_ui'
import '@/assets/style/index.scss';
import '@/utils/class_component_hook'
import VueCropper from 'vue-cropper'
import { setInitial } from '@/utils/local_storage';
// 组件
import globalComponent from '@/components/Global/index'
import validateInput from './directive/validateInput'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

Vue.config.productionTip = false
setInitial();
Vue.use(globalComponent);
Vue.directive('validate-input', validateInput)
Vue.use(VueCropper)
new Vue({
  router: Router,
  i18n: VI18n,
  store: Store,
  render: h => h(App),
}).$mount('#app')
